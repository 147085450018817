import { apiGetQuestion, apiQuestionSave } from "@/api/api.js";
import draggable from "vuedraggable";
import canAutoPlay from "can-autoplay";
import AudioTool from "@/components/AudioTool/AudioTool.vue";
import Recorder from "@/components/Recorder/Recorder.vue";
import AudioPlayer from "@/components/AudioPlayer/AudioPlayer.vue";
import * as upload from "@/utils/upload";

export default {
  components: {
    draggable,
    AudioTool,
    Recorder,
    AudioPlayer
  },
  props: {
    title: String,
    questionId: null,
    status: null,
    claId: null
  },
  data() {
    return {
      // 模块显示
      greTitleView: false,
      greTextView: false,
      greAudioView: false,
      greSelectView: false,
      greRadioSelectView: false,
      greMyPracticeView: false,
      greSubmitView: false,
      greDragView: false,
      greFillView: false,
      greFillInputView: false,
      greRecorderView: false,
      greRecorderViews: false,
      tipsViews: true,
      recorderTextView: false, //建议意群开关控制
      // 机经详情
      greTitle: "",
      greSelectList: [], //选择题数据
      greSelectTitle: "", //选择题问题
      greSelectedList: [],
      greSelected: "", //已选择的数据
      greRadioTitle: "",
      greRadioSelectList: [], //选择题数据
      greRadioSelectTitle: "", //选择题问题
      greMcsSelectTitle: "",
      greRadioSelectedList: [],
      greRadioSelected: "", //已选择的数据
      greText: [], //题目内容
      greTip: "", //提示信息
      greDragList: [], //拖拽数据
      grePracticeText: "", //我的练习内容
      grePracticeTextNum: 0, //联系字数统计
      greAudio: "", //音频数据
      greRecorder: "", //录音数据
      greCommonData: [], //公用数组
      greImgSrc: null, //图片链接
      recorderUrl: "", //录音链接
      recorderArticle: null, //录音识别数据

      // 填空题
      greFill: [],
      greFillOption: [],
      greFillSelected: [],

      // 切换按钮
      btnShowGist: false, //显示主旨
      btnShowTranslate: false, //显示翻译
      btnLoud: false, //建议重读
      btnPunctuation: false, //建议断句
      btnAdvise: false, //建议意群

      // 加载
      loading: false,
      // 传给父组件的值
      toDadyData: {
        count: null,
        collected: false,
        data: null
      }
    };
  },
  mounted() {
    this.getQuestion();
  },
  methods: {
    getQuestion() {
      if (this.questionId == null) {
        return;
      }
      this.loading = true;
      let paramsBase = {
        id: this.questionId
      };
      apiGetQuestion(paramsBase).then(res => {
        if (res.code != 200) {
          this.loading = false;
        }
        console.log(res.data);
        this.greSelectedList = [];
        this.loading = false;
        // 传给父组件的值赋值
        this.toDadyData.count = res.data.top.time;
        this.toDadyData.collected = res.data.top.collected;
        this.toDadyData.data = res.data;
        this.toDady();
        // 添加关键字段到session
        let questionDetail = {
          id: res.data.id,
          title: res.data.title,
          collected: res.data.top.collected,
          time: res.data.top.time,
          previousID: res.data.previousID,
          nextId: res.data.nextId,
          all: res.data.top.all,
          crt: res.data.top.crt,
          subtitles: res.data.subtitles,
          content: res.data.content
        };
        // console.log(res.data.top.collected)
        // console.log('传给session的题目的值', questionDetail)
        sessionStorage.setItem(
          "questionDetail",
          JSON.stringify(questionDetail)
        );

        let optionCount = 1; //选项计数
        // 公共赋值
        this.greTitle = res.data.title;
        this.greTitleView = true;
        this.greFill = this.greFillSelected = [];

        if (
          "MCM".indexOf(this.title) >= 0 &&
          sessionStorage.getItem("classify") == "Read"
        ) {
          this.greTitle = res.data.check.title;
          this.greSelectTitle = res.data.check.stem;
          this.greSelectList = res.data.check.options;
          this.greSelectView = true;
          if (this.status == null) {
            this.greSubmitView = true;
          }
        }
        if ("RO".indexOf(this.title) >= 0) {
          this.greDragList = res.data.check.items;
          this.greDragList.forEach((item, index) => {
            item.index = index;
          });
          this.greDragView = true;
          if (this.status == null) {
            this.greSubmitView = true;
          }
        }
        if ("RFIB".indexOf(this.title) >= 0 && this.title != "FIB") {
          // 填空题构建
          this.greFillOption = res.data.content.choose;
          this.greFill = res.data.content.article;
          optionCount = 0; //选项计数
          for (let i = 0; i < this.greFill.length; i++) {
            if (this.greFill[i].text == "_") {
              this.greFillSelected.push("");
              this.greFill[i].count = optionCount;
              optionCount = optionCount + 1;
            }
          }
          this.greFillView = true;
          if (this.status == null) {
            this.greSubmitView = true;
          }
        }
        if ("FIBW".indexOf(this.title) >= 0 && this.title != "FIB") {
          this.greFill = res.data.content.article;
          this.greFillView = true;
          if (this.status == null) {
            this.greSubmitView = true;
          }
          optionCount = 0; //选项计数
          for (let i = 0; i < this.greFill.length; i++) {
            if (this.greFill[i].text == "_") {
              this.greFillSelected.push("");
              this.greFill[i].count = optionCount;
              optionCount = optionCount + 1;
            }
          }
          // console.log(this.greFill)
        }
        if ("SWT,WE".indexOf(this.title) >= 0) {
          this.greText = res.data;
          this.greTextView = true;
          if (this.status == null) {
            this.greSubmitView = true;
            this.greMyPracticeView = true;
          }
        }
        if ("SST,WFD".indexOf(this.title) >= 0) {
          this.greAudio = res.data.audio;
          this.greAudioView = true;
          if (this.status == null) {
            this.greSubmitView = true;
            this.greMyPracticeView = true;
          }
        }
        if ("WFD".indexOf(this.title) >= 0) {
          this.tipsViews = false;
        }
        if ("MCM,HCS,SMW".indexOf(this.title) >= 0) {
          if (sessionStorage.getItem("classify") == "Read") {
            this.greSelectTitle = res.data.check.stem;
          } else {
            this.greSelectTitle = res.data.check.title;
          }
          this.greSelectList = res.data.check.options;
          this.greAudio = res.data.audio;
          this.greAudioView = this.greSelectView = true;
          if (this.status == null) {
            this.greSubmitView = true;
          }

          window.console.log("greAudio:", this.greAudio, this.greAudioView);
        }
        if ("MCS".indexOf(this.title) >= 0) {
          this.greAudio = res.data.audio;
          this.greAudioView = this.greSelectView = true;
          this.greRadioTitle = res.data.check.title;
          this.greRadioSelectTitle = res.data.check.title;
          this.greMcsSelectTitle = res.data.check.stem;
          this.greRadioSelectList = res.data.check.options;
          this.greRadioSelectView = true;
          if (this.status == null) {
            this.greSubmitView = true;
          }
        }
        if ("FIB".indexOf(this.title) >= 0) {
          this.greAudio = res.data.audio;
          this.greFill = res.data.content.article;
          optionCount = 0; //input计数
          for (let i = 0; i < this.greFill.length; i++) {
            if (this.greFill[i].text == "_") {
              this.greFillSelected.push("");
              this.greFill[i].count = optionCount;
              optionCount = optionCount + 1;
            }
          }
          this.greAudioView = this.greFillInputView = true;
          if (this.status == null) {
            this.greSubmitView = true;
          }
        }
        if ("HIW".indexOf(this.title) >= 0) {
          this.greAudio = res.data.audio;
          this.greText = res.data.content.article.map((item, index) => {
            return {
              ...item,
              index
            };
          });
          for (let i = 0; i < this.greText.length; i++) {
            this.greText[i].active = false;
          }
          console.log(this.greText);
          this.greAudioView = this.greTextView = true;
          if (this.status == null) {
            this.greSubmitView = true;
          }
        }
        if ("RA".indexOf(this.title) >= 0) {
          this.greRecorder = res.data.content.article;
          this.greRecorderView = true;
          if (this.status == null) {
            this.greRecorderViews = true;
          }
        }
        if ("RS".indexOf(this.title) >= 0) {
          this.greRecorder = res.data.subtitles.article;
          this.greAudio = res.data.audio;
          this.greCommonData = res.data.sense_group.content;
          this.greAudioView = true;
          this.greRecorderView = true;
          if (this.status == null) {
            this.greRecorderViews = true;
          }
          this.btnLoud = this.btnPunctuation = true;
        }
        if ("DI".indexOf(this.title) >= 0) {
          this.greImgSrc = res.data.img.url;
          this.greRecorderView = true;
          if (this.status == null) {
            this.greRecorderViews = true;
          }
        }
        if ("RL".indexOf(this.title) >= 0) {
          this.greRecorder = res.data.subtitles.article;
          this.greAudio = res.data.audio;
          this.greAudioView = true;
          this.greRecorderView = true;
          if (this.status == null) {
            this.greRecorderViews = true;
          }
        }
        if ("ASQ".indexOf(this.title) >= 0) {
          if (res.data.audio.url.length > 0) {
            this.greAudio = res.data.audio;
            this.greAudioView = true;
          } else {
            this.greAudioView = false;
          }
          if (res.data.img != null) {
            this.greImgSrc = res.data.img.url;
          } else {
            this.greImgSrc = null;
          }
          this.greRecorderView = true;
          if (this.status == null) {
            this.greRecorderViews = true;
          }
        }

        if (this.greRecorderView) {
          this.recorderUrl = null;
          this.recorderArticle = null;
        }
        this.$nextTick(() => {
          this.isSupportAutoPlay().then(({ result }) => {
            if (result === true) {
              // Can auto-play
            } else {
              this.$emit("record");
              this.$emit("unSupportAutoPlay");
            }
          });
        });
      });
    },
    // 点击单词
    tapWord(e) {
      if (e.active) {
        e.active = false;
        this.greFillSelected.forEach((item, index) => {
          if (item == e.index) {
            this.greFillSelected.splice(index, 1);
          }
        });
      } else {
        e.active = true;
        this.greFillSelected.push(e.index);
      }
      this.$forceUpdate();
    },
    // 给父组件传值
    toDady() {
      this.$emit("getMySon", this.toDadyData);
    },
    // 给父组件传递音频状态
    getAudio(data) {
      this.$emit("audioPlay", data);
    },
    // 提交问题
    submitQuestion() {
      if (!this.greSubmitView) return;
      let contents = [];
      var formData = new window.FormData();
      formData.append("qid", this.questionId);
      formData.append("claid", this.claId);
      /*
            if (this.greSelected.length != '') {
                // 暂无数据
                formData.append("contents[0]", this.greSelected);
            }*/

      if (this.greSelectedList.length > 0) {
        // 暂无数据
        this.greSelectedList.forEach(function(item, index) {
          formData.append("contents[" + index + "]", item);
        });
      }

      if (this.greRadioSelectedList.length > 0) {
        formData.append(
          "contents[0]",
          this.greRadioSelectList
            .map(item => item.text)
            .indexOf(this.greRadioSelectedList)
        );
      }

      if (this.grePracticeText.length != "") {
        formData.append("contents[0]", this.grePracticeText);
      }
      if (this.greDragList.length > 0) {
        let idxArr = [];
        this.greDragList.forEach(item => {
          idxArr.push(item.index);
        });
        idxArr.forEach((element, index) => {
          formData.append("contents[" + [index] + "]", element);
        });
      }
      if (this.greFillSelected.length > 0) {
        this.greFillSelected.forEach((element, index) => {
          formData.append("contents[" + [index] + "]", element);
        });
      }
      let paramsBase = formData;
      return apiQuestionSave(paramsBase).then(res => {
        sessionStorage.setItem("claid", null);
        this.$layer.msg("提交成功");
        console.log(res);
        sessionStorage.setItem("answerDetail", JSON.stringify(res.data));
        this.$parent.childSubmit();
      });
    },
    getMsgFormSon(data) {
      var formData = new window.FormData();
      var name = this.getMath() + this.getMath() + this.getMath() + ".wav";
      formData.append("claid", this.claId); //注意替换成作业id,从班级作业过来的有此值
      formData.append("qid", this.questionId);

      const loading = this.$loading({
        lock: true,
        text: this.$t("m.loadingRecord"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
        target: document.querySelector(".gre-recorder-submit__inner")
      });
      upload.file({
        file: data,
        fileName: name,
        onSuccess: ({ data }) => {
          formData.append("oss_url", data.oss_url);
          let paramsBase = formData;
          this.recorderUrl = data.oss_url;

          loading.close();

          apiQuestionSave(paramsBase).then(res => {
            sessionStorage.setItem("claid", null);
            if (
              res.data.content[1] &&
              res.data.content[1].article.length != 0
            ) {
              this.recorderArticle = res.data.content[1].article;
            }
            this.$parent.saveData = res.data;
          });
        }
      });
    },
    //字数统计
    descInput() {
      var reg = /(\w)+/gi;
      var txtVal = this.grePracticeText.match(reg).length;
      this.grePracticeTextNum = txtVal;
    },
    //生成随机数
    getMath() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    },

    clearValue() {
      this.grePracticeText = "";
    },

    playAudio() {
      if (
        this.greAudioView &&
        this.greAudio != "" &&
        this.greAudio != undefined
      ) {
        this.$refs.audioToolRef.playAudio();
      }
    },

    record() {
      this.$emit("record");
    },

    isSupportAutoPlay() {
      return canAutoPlay.audio();
    }
  },
  watch: {
    questionId() {
      this.greAudioView = false;
      this.getQuestion();
    }
  }
};
