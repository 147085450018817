import { mapState } from 'vuex'

export default {
  props: {
    count: {
      type: Number
    },
    showSeconds: {
      type: Boolean,
      default: false
    },
    id: null
  },
  data() {
    return {
      timeOjb: null,
      timeCount: 0,
      time: '',
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  },
  computed: {
    ...mapState('app', {
      userInfo: (state) => state.userInfo
    })
  },
  watch: {
    'userInfo.is_auto_play'() {
      this.startCountDown()
    }
  },
  mounted() {
    this.startCountDown()
  },
  methods: {
    startCountDown() {
      clearInterval(this.timeId)

      this.timeCount = this.count * 1000
      this.countDown()

      if (this.userInfo.is_auto_play == 2) {
        return
      }

      this.timeId = setInterval(() => {
        if (this.timeCount < 0) {
          clearInterval(this.timeId)
          this.$emit('endCountDown')
          return
        }
        this.countDown()
      }, 1000)
    },
    countDown() {
      this.timeObj = {
        seconds: Math.round((this.timeCount % (1000 * 60)) / 1000),
        minutes: Math.floor((this.timeCount % (1000 * 60 * 60)) / (1000 * 60)),
        hours: Math.floor(
          (this.timeCount % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
      }
      const countSeconds = Math.round(this.timeCount / 1000)
      let add = (num) => {
        return num < 10 ? '0' + num : num
      }
      this.hours = `${add(this.timeObj.hours)}`
      this.minutes = `${add(this.timeObj.minutes)}`
      this.seconds = `${add(this.timeObj.seconds)}`
      this.time = this.showSeconds
        ? countSeconds
        : this.minutes + ':' + this.seconds
      this.timeCount = this.timeCount - 1000
    }
  }
}
