import GreQuestion from '@/components/GreQuestion/GreQuestion.vue'
import GreAnswers from '@/components/GreAnswers/GreAnswers.vue'
import CountDown from '@/components/CountDown/CountDown.vue'
import AudioTool from '@/components/AudioTool/AudioTool.vue'

import { apiGetQuestionList, apiGetTeacherDetail, apiAskQuestions, apiQuestionCateCount, apiCollect, apiDelCollect, apiQuestionSave, apiGetDemonstration } from '@/api/api.js'
export default {
    components: {
        CountDown: CountDown,
        GreQuestion: GreQuestion,
        AudioTool: AudioTool,
        GreAnswers: GreAnswers,
    },
    data() {
        return {
            title: sessionStorage.getItem('type'),//标题
            count: 0,//倒计时
            greQuestionView: true,//机经练习显示
            greAnswersView: false,//字幕答案答题记录显示
            submitBtnLoading: false,
            countView: true,//计时器
            emptyView: false,//无数据
            sonData: null,// 子组件的值
            credit: 0,//需要的钱
            hasDetail: false,//老师详解
            loading: false,//加载
			autoStatus: true, //音频是否自动播放
            topMenu: [
                true, false, false
            ],
            reSet: true,
            secondTabName: "",

            // 下拉菜单
            greDropList: true,
            greChooseId: null,
			greclaId: sessionStorage.getItem('claid'),
			claidStatus: sessionStorage.getItem('claid_status'),
			

            // 参数
            questionListParamsBase: {
                clid: sessionStorage.getItem('clid'),
                page: 1,
                pageNum: 10,
                type: 1,
                keyword: ""
            },
            // 当前选中题目前后id
            listIdx: null,
            titleIdx: null,
            prevId: null,
            nextId: null,

            // 题目字段信息
            questionDetail: {},

            // 保存题目返回的信息
            saveData: [],

            // 底部菜单数据
            teacherDetail: {},//老师详解
            teacherAsk: "",//向老师提问
            collected: false,//是否收藏

            // 弹框
            dialogTeacherDetail: false,
            dialogTeacherAsk: false,
            // tab数据
            fristLoad: true,
            tabOn: '1',
            dropTitle: "全部",
			lastlist:[],//请求过的数据
            tabData: [
                {
                    type: 'all',
                    id: '1',
                    page: 1,
                    list: [],
                    finish: false,
                    num: 0
                },
                {
                    type: 'recurring',
                    id: '2',
                    page: 1,
                    list: [],
                    finish: false,
                    num: 0
                },
                {
                    type: 'gre',
                    id: '3',
                    page: 1,
                    list: [],
                    finish: false,
                    num: 0
                },
                {
                    type: 'questions',
                    id: '4',
                    page: 1,
                    list: [],
                    finish: false,
                    num: 0
                },
            ],
            recorded:false,
            isSupportAutoPlay: true,
            isScoll:true
        }
    },
    mounted() {
        this.loadQuestionList();
        this.setSecondTabName();
    },
    methods: {
        // 判断第二个菜单名字
        setSecondTabName() {
            if ('RA'.indexOf(this.title) >= 0) {
                this.secondTabName = 'expertDemonstration'
            } else {
                this.secondTabName = 'transcriptAnswerKeys'
            }
        },

        // 点击tab
        handleTab(e) {
            this.isScoll = true;
            // 判断是否要加载
            if (this.tabData[e.index].finish == true) {
                return
            }
            // 设置参数
            console.log(e.index);
            this.dropTitle = e.label;
            this.questionListParamsBase.type = this.tabData[parseInt(e.index)].id;
            this.questionListParamsBase.page = this.tabData[e.index].page;
            this.loadQuestionList();

        },
        // 滚动加载
        loadQuestionList() {
            let paramsBase = this.questionListParamsBase;
            //console.log(this.tabData[this.tabOn - 1].finish );
            if(!this.isScoll){
                return;
            }
            this.isScoll = false;
            apiGetQuestionList(paramsBase).then((res) => {
                
                //console.log(this.tabData[this.tabOn - 1].finish );
                if(res.code==200){
                        // 加载数据
                        this.tabData[this.tabOn - 1].list.push.apply(this.tabData[this.tabOn - 1].list, res.data.list);
                        // 判断是否有数据
                        if (this.fristLoad && res.data.list.length == 0) {
                            this.emptyView = true
                            this.greQuestionView = false
                            return
                        } else {
                            this.emptyView = false
                            if (this.topMenu[0]) {
                                this.greQuestionView = true
                            }
                        }
                        // 判断是否到最后一页
                        if (res.data.list.length == 0) {
                            this.tabData[this.tabOn - 1].finish = true;
                        } else {
                            this.isScoll = true;
                            this.tabData[this.tabOn - 1].page += 1;
                            //console.log(this.tabData[this.tabOn - 1].page)
                            this.questionListParamsBase.page = this.tabData[this.tabOn - 1].page;
                        }
                        // 是否初次加载，默认加载第一题
                        if (this.fristLoad) {
                            // 初始化第一个题目
        					this.greChooseId = this.tabData[0].list[0].id;
                            this.fristLoad = false;
                            this.getAdjoinId(0, 0);
                            sessionStorage.setItem('answerDetail', null)
                            // 初始化数量
                            apiQuestionCateCount({ gre_cate_id: sessionStorage.getItem('clid') }).then((res) => {
                                this.tabData[0].num = res.data.all;
                                this.tabData[2].num = res.data.gre_count;
                                this.tabData[1].num = res.data.hot_count;
                                this.tabData[3].num = res.data.practice_test_count;
                            })
                        }
                 }
                // 判断数据来源
                if (this.$route.query.id) {
                    this.greChooseId = this.$route.query.id
                }
                if (sessionStorage.getItem('tab')) {
                    this.selectMenu(parseInt(sessionStorage.getItem('tab')))
                    sessionStorage.setItem('tab', '')
                }
            })
        },
        // 打开，收起下拉菜单
        handleGreDropList() {
            if (this.greDropList == true) {
                this.greDropList = false
            } else {
                this.greDropList = true;
            }
        },

        // 获取题目菜单字段
        getQuestionDetail() {
            this.questionDetail = JSON.parse(sessionStorage.getItem('questionDetail'))
        },

        // 点击菜单内容
        handleGetQuestion(id, listIdx, titleIdx, hasDetail) {
            sessionStorage.setItem('answerDetail', null)
            this.hasDetail = hasDetail
            this.getAdjoinId(listIdx, titleIdx)
            this.greChooseId = id;
            this.selectMenu(0);
            this.greDropList = false;

        },
        // 获取上下题目id
        getAdjoinId(listIdx, titleIdx) {
            this.listIdx = listIdx;
            this.titleIdx = titleIdx;
            // 判断是否第一题
            if (titleIdx == 0) {
                this.prevId = null;
            } else {
                this.prevId = this.tabData[listIdx].list[titleIdx - 1].id
            }
            // 判断是否为最后一题
            if (titleIdx == this.tabData[listIdx].list.length - 1) {
				this.loadQuestionList()
				if(titleIdx == this.tabData[listIdx].list.length - 1){
					this.nextId = null;
				}
            } else {
                this.nextId = this.tabData[listIdx].list[titleIdx + 1].id;
            }
        },
        // 老师详解
        getTeacherDetail() {
            if (this.emptyView) {
                return;
            }
            this.teacherDetail = {
                teacherName: null,
                teacherImg: null,
                text: null,
                video: null,
                audio: {
                    url: null
                }
            }
            let paramsBase = {
                ggid: this.ggid
            }
            apiGetTeacherDetail(paramsBase).then((res) => {
                if (res.data == null) {
                    this.$layer.msg(res.msg)
                } else {
                    this.teacherDetail.teacherName = res.data.name;
                    this.teacherDetail.teacherImg = res.data.img_url;
                    for (let i = 0; i < res.data.detail.length; i++) {
                        let type = res.data.detail[i].type;
                        if (type == 'content') {
                            this.teacherDetail.text = res.data.detail[i].content.text;
                        }
                        if (type == 'audio') {
                            this.teacherDetail.audio.url = res.data.detail[i].content;
                        }
                        if (type == 'video') {
                            this.teacherDetail.video = res.data.detail[i].content;
                        }
                    }
                    this.dialogTeacherDetail = true;
                }
            })
        },

        // 打开向老师提问的弹框
        askQuestionsDialog() {
            if (this.emptyView) {
                return
            }
            this.getQuestionDetail();
            this.dialogTeacherAsk = true;
        },

        // 向老师提问
        async askQuestions() {
            if (this.emptyView) {
                return;
            }
            
            if (this.$refs.questionRef) {
                await this.$refs.questionRef.submitQuestion()
            }
            let uqid = 0;
            if(this.saveData.uqid){
                uqid = this.saveData.uqid;
            }
            this.submitBtnLoading = true;
            let paramsBase = {
                qid: this.greChooseId,
                content: this.teacherAsk,
                uqid: uqid
            }
            apiAskQuestions(paramsBase).then((res) => {
                if (res.code == 200) {
                    this.$layer.msg('提问成功，老师会尽快回复!')
                    this.dialogTeacherAsk = false;
                } else {
                    this.$layer.msg('提交失败了')
                }
                this.submitBtnLoading = false;
            })

        },
        // 关闭弹框清数据
        closeDialog() {
            this.teacherAsk = ''
        },
        // 上一题
        prevQuestion() {
            this.$refs.questionRef && this.$refs.questionRef.clearValue()
            /*
            if (this.topMenu[1] || this.topMenu[2]) {
                return
            }*/
            this.selectMenu(0);
            this.getQuestionDetail();
            this.getAdjoinId(this.listIdx, this.titleIdx)
            if (this.prevId == null) {
                this.$layer.msg('当前题目为第一题')
                return;
            } else {
                this.greChooseId = this.prevId;
                this.titleIdx = this.titleIdx - 1
                this.getAdjoinId(this.listIdx, this.titleIdx)
            }

        },
        // 下一题
        nextQuestion() {
			this.loading = true;
            this.$refs.questionRef && this.$refs.questionRef.clearValue()
            /*
            if (this.topMenu[1] || this.topMenu[2]) {
                return
            }
            */
		    //this.loadQuestionList();
            this.selectMenu(0);
            this.getQuestionDetail();
            this.getAdjoinId(this.listIdx, this.titleIdx)
			setTimeout(this.closeLoading,200); 
            if (this.nextId == null) {
                this.$layer.msg('当前题目为最后一题')
                return;
            } else {
                this.greChooseId = this.nextId;
                this.titleIdx = this.titleIdx + 1
                this.getAdjoinId(this.listIdx, this.titleIdx)
            }
        },
		// 关闭加载
		closeLoading(){

			this.loading = false;
		},
        //列表收藏
        collectQuestion1(id,indexs){
            
            console.log(id,indexs,this.tabOn)
            let paramsBase = {
                aid: id,
                paramerid: id,
                type: 'practice'
            }
             
            apiCollect(paramsBase).then((res) => {
                let collect = this.tabData[this.tabOn-1].list[indexs].is_collect
                console.log(this.greChooseId,id)
                if(res.code==200){
                      if(collect==1){
                            this.$layer.msg('已取消收藏!')
                      }else{
                             this.$layer.msg('收藏成功!')
                            
                      } 
                      let arr = this.tabData
                      for(let i=0;i<arr.length;i++){

                        let child = arr[i].list
                        if(child&&child.length>0){
                            for(let j=0;j<child.length;j++){
                               
                                    //console.log(arr[i].type)
                                      
                                   
                                   if(child[j].id==id){
                                    //console.log(arr[i].type,child[j].id,id)
                                        if(collect==1){
                                        
                                            child[j].is_collect = false
                                        }else{
                                            child[j].is_collect = true   
                                        }  
                                    }  
                                     
                            }
                            this.$set(this.tabData[i], "list", child)
                        }
                      } 
                      
                      if(this.greChooseId==id){
                            if(collect==1){
                            
                                this.collected = false
                            }else{
                                  this.collected = true   
                            }  
                        }  
                      
                      this.tabData[this.tabOn-1].list[indexs].is_collect = collect?0:1;
                }
                //this.$forceUpdate();
                
            })
            
        },
        // 收藏
        collectQuestion() {

            if (this.emptyView) {
                return;
            }
            this.getQuestionDetail();
            let paramsBase = {
                aid: this.greChooseId,
                paramerid: this.greChooseId,
                type: 'practice'
            }
            if (this.collected == true) {
                apiDelCollect(paramsBase).then((res) => {
                    this.$layer.msg('已取消收藏')
                    this.collected = false;
                    this.tabData[this.listIdx].list[this.titleIdx].is_collect = 0;
                })
            } else {
                apiCollect(paramsBase).then((res) => {
                    this.$layer.msg('收藏成功!')
                    this.collected = true;
                    this.tabData[this.listIdx].list[this.titleIdx].is_collect = 1;
                })
            }

            let arr = this.tabData
              for(let i=0;i<arr.length;i++){

                let child = arr[i].list
                if(child&&child.length>0){
                    for(let j=0;j<child.length;j++){

                           if(child[j].id==this.greChooseId){

                               child[j].is_collect = this.tabData[this.listIdx].list[this.titleIdx].is_collect
                                
                            }  
                             
                    }
                    this.$set(this.tabData[i], "list", child)
                }
              } 
        },

        // 子组件提交
        childSubmit() {
            this.saveData = JSON.parse(sessionStorage.getItem('answerDetail'))
            this.greQuestionView = false;
            this.greAnswersView = true;
            for (let i = 0; i < this.topMenu.length; i++) {
                this.topMenu[i] = false
            }
            this.topMenu[1] = true;
        },

        // 查看题目的字幕，答案，答题记录
        selectMenu(num) {
			sessionStorage.setItem('answerDetail', null)
            if (this.emptyView) {
                return;
            }
            let active = this.topMenu.indexOf(true)
            if (active == num) {
                return
            }
            this.greDropList = false;//关闭下拉菜单
            this.loading = true;
            for (let i = 0; i < this.topMenu.length; i++) {
                this.topMenu[i] = false
            }
            this.topMenu[num] = true;
            if (num == 0) {
                this.greQuestionView = true;
                this.greAnswersView = false;
                this.loading = false;
            }
            if (num == 1 || num == 2) {
                if (active == 1 || active == 2) {
                    this.loading = false;
                    return
                }
                // ---------------------------------------------
                // 保存题目!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                // ----------------------------------------------
                var formData = new window.FormData();
                formData.append("qid", this.greChooseId);
                let paramsBase = {
                    qid: this.greChooseId
                }
                let paramsBase2 = {
                    id: this.greChooseId
                }
                // 判断是否为特殊题型RL
                if (this.title == 'RL' || this.title == 'DI') {
                    this.saveData = this.sonData.data;
                    this.greQuestionView = false;
                    this.greAnswersView = true;
                    this.loading = false;
                    return
                }

                if (this.title == 'RS' || this.title == 'ASQ') {
					this.saveData = this.sonData.data;
                    this.greQuestionView = false;
                    this.greAnswersView = true;
                    this.loading = false;
                    return
                }


                // 判断是老师示范还是字幕/答案
                if (JSON.parse(sessionStorage.getItem('answerDetail'))) {
                    this.greQuestionView = false;
                    this.greAnswersView = true;
                    this.loading = false;
                    return
                }
                else if (this.secondTabName == 'expertDemonstration') {
                    this.greQuestionView = false;
                    this.greAnswersView = true;
                    this.loading = false;
                    apiGetDemonstration(paramsBase2).then((res) => {
                        if (res.code != 200) {
                            this.loading = false;
                            return
                        }
                        this.saveData.demonstration = res.data.demonstration;
                        this.saveData.img_url = res.data.img_url;
                        this.saveData.name = res.data.name;
                    })
                } else {
                    paramsBase = formData;
                    apiQuestionSave(paramsBase).then((res) => {
                        if (res.code == '200') {
                            // console.log('保存题目返回的数据', res)
                            this.saveData = res.data;
                            this.greQuestionView = false;
                            this.greAnswersView = true;
                            this.loading = false;
                        } else {
                            this.loading = false;
                            this.$layer.msg('该题目异常!')
                        }

                    })
                }
            }
            this.$forceUpdate()
        },
        // 接收子组件的数据
        getMySon(e) {
            this.sonData = e;
            this.collected = this.sonData.collected;
            this.credit = this.sonData.data.credit;
            this.count = this.sonData.count;
            // console.log(this.count)
            this.countView = false;
            this.hasDetail = this.sonData.data.hasDetail
			this.ggid = this.sonData.data.ggid
            this.$nextTick(() => {
                if (sessionStorage.getItem('classify') != 'Read') {
                    this.countView = true
                }

            })
        },
        hideGreTab() {
            this.greDropList = false
        },

        record() {
            this.recorded = true
        },

        endCountDown() {
            if (this.recorded) return
			if (this.autoStatus == false) return
            this.$refs.questionRef && this.$refs.questionRef.playAudio()
        },

        unSupportAutoPlay() {
            this.isSupportAutoPlay = false
        },
		getPlaystatus(data){
			if(data == 'ok'){
				this.autoStatus = false
			}
		},
		prev(){
			this.$router.go(-1)
			sessionStorage.setItem('claid_status',0)
		}
		
    },

}