// import {  } from '@/api/api.js'
import AudioTool from '@/components/AudioTool/AudioTool.vue'
import AudioPlayer from '@/components/AudioPlayer/AudioPlayer.vue'

import { apiGetMyRecord } from '@/api/api.js'
export default {
    components: {
        AudioTool, AudioPlayer
    },
    props: {
        saveData: null,
        type: null,
        title: null,
        id: null,
    },
    data() {
        return {
            answersActive: 0,//查看答题
            tabActive: 'first',
            // 加载
            loading: false,
            myRecord: null,
            audioRecord: false,
            reSet: true,
        }
    },
    watch: {
        id(val, oldVal) {
            this.init();
            this.getMyRecord();
        }
    },
    mounted() {
        this.init();
        this.getMyRecord();
    },
    methods: {
        init() {
            // 判断是否为特殊题型ASQ
            if (this.title == 'ASQ' || this.title == 'RS') {
                this.saveData.subtitles = JSON.parse(sessionStorage.getItem('questionDetail')).subtitles
            }
            if (this.title == 'RL') {
                this.saveData.subtitles = JSON.parse(sessionStorage.getItem('questionDetail')).subtitles
            }

            if (this.title == 'MCM' && sessionStorage.getItem('classify') == 'Read') {
                this.saveData.subtitles = undefined
            }
            if (this.title == 'MCS' && sessionStorage.getItem('classify') == 'Read') {
                this.saveData.subtitles = undefined
            }
            if (this.title == 'DI') {
                // console.log(this.saveData)
            }
            if (this.title == 'RA') {
                this.saveData.subtitles = JSON.parse(sessionStorage.getItem('questionDetail')).content
            }
            if (this.type == 'expertDemonstration') {
                this.tabActive = 'third'
            }
            if (this.saveData.subtitles == undefined) {
                this.tabActive = 'second';
            }
        },
        handleClick() { },
        // 获取答题记录
        getMyRecord() {
			console.log(this.saveData.answer)
            let paramsBase = {
                id: JSON.parse(sessionStorage.getItem('questionDetail')).id
            }
            apiGetMyRecord(paramsBase).then((res) => {
				
                this.myRecord = res.data;
				console.log(this.myRecord)
                if (res.data.length > 0 && res.data[0].content[0].type == 'audio') {
                    this.audioRecord = true;
                    return
                }
                let recordLen = this.myRecord.length
                if (recordLen < 4) {
                    let newArrNum = 4 - recordLen;
                    let arr = {
                        date: '无',
                        noData: true,
                        content: [{
                            content: this.$t('gre.noThing')
                        }]
                    }
                    for (let i = 0; i < newArrNum; i++) {
                        this.myRecord.push(arr);
                    }
                }
				 //console.log(this.saveData.myAnswer)
				if(this.saveData.myAnswer){
					let myanswer = '';
					for(let j = 0;j < this.saveData.myAnswer.article.length; j++){
						myanswer+= '<span style="color:'+ this.saveData.myAnswer.article[j].color +';margin:0 5px;">' + this.saveData.myAnswer.article[j].text + '</span>';
					}
					this.myRecord[0].content[0].content = myanswer;
					this.myRecord[0].content[0].answer_status = 1;
				}
            })
        }

    },
}